// src/components/routes/Landing/Team/Team.js

import React from "react";
import "./Team.css";

const Team = () =>{
    return(
        <div>
            <section className='team-container' >
                <div className='headings'>
                    <div className='heading-1 t-h1'>EXPERTISE</div>
                    <div className='heading-2 t-h2'>Our Team</div>
                </div>

                <div className='team-members'>
                    <span className='team-member'>
                    <div className="info-icon">ℹ️</div>
                        <img 
                            src="./uploads/person-1.png"
                            className='member-1 memb'
                            alt='member-1'
                        />
                        <div className='overlayy'>
                            <div className='textin'>Dheeraj Hellan</div>
                            <div className='textinn'>-Founder & Director</div>
                        </div>
                    </span>
                    <span className='team-member'>
                    <div className="info-icon">ℹ️</div>
                        <img 
                            src="./uploads/person-2.png"
                            className='member-2 memb'
                            alt='member-2'
                        />
                        <div className='overlayy'>
                            <div className='textin'>Ankit Kumar</div>
                            <div className='textinn'>-Creative Producer & Creative Director</div>
                        </div>
                    </span>
                    <span className='team-member'>
                    <div className="info-icon">ℹ️</div>
                        <img 
                            src="./uploads/person-4.png"
                            className='member-3 memb'
                            alt='member-3'
                        />
                        <div className='overlayy'>
                            <div className='textin'>Antriksh Gahlot</div>
                            <div className='textinn'>-Post Production Head</div>
                        </div>
                    </span>
                    <span className='team-member'>
                    <div className="info-icon">ℹ️</div>
                        <img 
                            src="./uploads/person-6.png"
                            className='member-6 memb'
                            alt='member-6'
                        />
                        <div className='overlayy'>
                            <div className='textin'>Deepansh</div>
                            <div className='textinn'>-Social Media Head & Marketing</div>
                        </div>
                    </span>
                    <span className='team-member'>
                    <div className="info-icon">ℹ️</div>
                        <img 
                            src="./uploads/person-3.png"
                            className='member-4 memb'
                            alt='member-4'
                        />
                        <div className='overlayy'>
                            <div className='textin'>Rishabh Ghosh</div>
                            <div className='textinn'>-Production Manager</div>
                        </div>
                    </span>
                    <span className='team-member'>
                    <div className="info-icon">ℹ️</div>
                        <img 
                            src="./uploads/person-5.png"
                            className='member-3 memb'
                            alt='member-5'
                        />
                        <div className='overlayy'>
                            <div className='textin'>Jyoti Verma</div>
                            <div className='textinn'>-Fashion & Costume Stylist</div>
                        </div>
                    </span>
                </div>
            </section>
        </div>
    )
};

export default Team;